@font-face {
    font-family: 'Lato';
    src: url(../font/Lato-Regular.9919edff6283018571ad.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(../font/Lato-Bold.5dd5aa0269395f0ea927.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(../font/Lato-Italic.0b4ed00316a7940bfc3f.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

:root {
    --header-color: #002a61;
    --header-txt1-color: #ffffff;
    --header-txt2-color: #e4e5f1;
    --header-txt3-color: #a9bed6;
    --header-btn-color: #ff944a;
    --header-btn-color2: #ff7b1e;
    --nav-li-bg: #00346dcc;
    --body-title-color: #002857;
    --body-text-color: #293447;
    --font-size-content: 18px;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Lato', sans-serif;
}

.zcontainer {
    width: 1196px;
    margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
    .zcontainer {
        width: 100%;
    }
}

/* Header  ↓↓↓ */

.logo {
    display: block;
    width: 203px;
    height: 35px;
    text-indent: -5000px;
    background-image: url(../image/logo.svg);
}

footer .logo {
    background-image: url(../image/logo-black.svg);
}

header {
    background-color: var(--header-color);
    background-image: url(../image/header.webp);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 1200px;
}

header h1 {
    color: var(--header-txt1-color);
    font-weight: 900;
    font-size: 80px;
    line-height: 96px;
    padding-top: 12px;
    margin-bottom: 13px;
}

header p.line1 {
    color: var(--header-txt1-color);
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 52px;
}

header p.line2 {
    color: var(--header-txt2-color);
    font-size: var(--font-size-content);
    line-height: 32px;
}

header .btn {
    width: 295px;
    height: 72px;
    margin-top: 35px;
    margin-bottom: 55px;
    color: var(--header-txt1-color);
    background-color: var(--header-btn-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .btn:hover {
    background-color: var(--header-btn-color2);
    cursor: pointer;
}

header .btn .txt {
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    margin-left: 24px;
    pointer-events: none;
}

header .btn .arrow {
    width: 60px;
    height: 58px;
    margin-right: 8px;
    border-radius: 20px;
    background-color: var(--header-btn-color2);
    background-image: url(../image/arrow-white.png);
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 20px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    text-decoration: none;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


/* Header navigation  ↓↓↓ */


nav ul {
    display: flex;
    padding-bottom: 64px;
}

nav ul li {
    list-style-type: none;
    margin-right: 25px;
    background-color: var(--nav-li-bg);
    border-radius: 10px;
    padding: 1px;
}

nav ul li a {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    text-decoration: none;
    color: var(--header-txt3-color);
    background-color: var(--nav-li-bg);
    background-repeat: no-repeat;
    background-position: 18px center;
}

nav ul li.item-upcoming a {
    background-image: url(../image/ico-upcoming.png);
}

nav ul li.item-upcoming a:hover {
    background-image: url(../image/ico-upcoming-hover.png);
}

nav ul li.item-faq a {
    background-image: url(../image/ico-faq.png);
}

nav ul li.item-faq a:hover {
    background-image: url(../image/ico-faq-hover.png);
}

nav ul li.item-about-us a {
    background-image: url(../image/ico-about-us.png);
}

nav ul li.item-about-us a:hover {
    background-image: url(../image/ico-about-us-hover.png);
}

nav ul li.item-roadmap a {
    background-image: url(../image/ico-roadmap.png);
}

nav ul li.item-roadmap a:hover {
    background-image: url(../image/ico-roadmap-hover.png);
}

nav ul li a span {
    margin-right: 20px;
    margin-left: 52px;
    pointer-events: none;
}

nav ul li:hover {
    cursor: pointer;
    background: linear-gradient(to right, transparent, #a2f4f5);
    animation-name: gradient-fade;
    animation-duration: 0.5s;
}

nav ul li:hover a {
    color: #a2f4f5;
    transition: color 0.3s linear;
}

@keyframes gradient-fade {
    0% {
        background: linear-gradient(to right, #9fcdff, #a2f4f5);
    }

    10% {
        background: linear-gradient(to right, #e8f3ff4f, #a2f4f5);
    }

    20% {
        background: linear-gradient(to right, #e8f3ff38, #a2f4f5);
    }

    30% {
        background: linear-gradient(to right, #e8f3ff2d, #a2f4f5);
    }

    40% {
        background: linear-gradient(to right, #e8f3ff21, #a2f4f5);
    }

    50% {
        background: linear-gradient(to right, #e8f3ff1a, #a2f4f5);
    }

    60% {
        background: linear-gradient(to right, #e8f3ff11, #a2f4f5);
    }

    70% {
        background: linear-gradient(to right, #e8f3ff09, #a2f4f5);
    }

    80% {
        background: linear-gradient(to right, #e8f3ff04, #a2f4f5);
    }

    90% {
        background: linear-gradient(to right, #e8f3ff04, #a2f4f5);
    }

    100% {
        background: linear-gradient(to right, transparent, #a2f4f5);
    }
}

/* body  ↓↓↓ */

.zbody-1 {
    background-color: #f1f6fb;
    padding-bottom: 50px;
    padding-top: 32px;
}

.zbody-2 {
    background-color: #ffffff;
}

h2.title-1 {
    padding-top: 80px;
    margin-bottom: 106px;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    color: var(--body-title-color);
    text-align: center;
}

/* Game description  ↓↓↓ */

.game-description {
    margin-top: 82px;
    margin-bottom: 38px;
    padding-bottom: 24px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
}

.game-description .logo-wrapper {
    width: 256px;
    height: 118px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
}

.top1 .logo-wrapper {
    background-image: url(../image/axie-infinity-logo.webp);
}

.top2 .logo-wrapper {
    background-image: url(../image/decentraland-logo.webp);
}

.top3 .logo-wrapper {
    background-image: url(../image/sorare-logo.webp);
}

.upcoming1 .logo-wrapper {
    background-image: url(../image/star-atlas-logo.png);
}

.upcoming2 .logo-wrapper {
    background-image: url(../image/illuvium-logo.png);
}

.upcoming3 .logo-wrapper {
    background-image: url(../image/guild-of-guardians-logo.png);
}

.game-description .game-title {
    text-align: center;
    font-size: 24px;
    font-weight: 900;
    line-height: 50px;
    margin-left: 46px;
    margin-top: 77px;
    color: var(--body-title-color);
}

.game-description .content {
    margin-left: 53px;
    margin-right: 68px;
    padding-bottom: 28px;
}

.game-description .content p {
    font-size: var(--font-size-content);
    line-height: 30px;
    color: var(--body-text-color);
    margin-bottom: 35px;
}

.game-description .footer .images {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}

.game-description .footer img:nth-of-type(1) {
    margin-left: 52px;
}

.game-description .footer img:nth-of-type(3) {
    margin-right: 55px;
}

.game-description .read-more-wrapper {
    padding-top: 5px;
}

.game-description .read-more {
    color: #f18539;
    height: 50px;
    width: 140px;
    font-weight: 900;
    font-size: 18px;
    line-height: 50px;
    border: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-description .read-more .img {
    background-image: url(../image/arrow.png);
    background-repeat: no-repeat;
    width: 16px;
    height: 18px;
    margin-left: 18px;
}

.game-description .read-more:hover {
    cursor: pointer;
}

.game-description .video-wrapper {
    margin-left: 53px;
    margin-right: 53px;
    padding-bottom: 28px;
    text-align: center;
}

.game-description .video-wrapper iframe {
    width: 100%;
    min-height: 420px;
    border-radius: 20px;
}

/* Game description footer youtube video ↓↓↓ */

.youtube {
    background-color: #000;
    margin-bottom: 30px;
    position: relative;
    padding-top: 40.25%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
}

.youtube img {
    width: 100%;
    top: -22.82%;
    left: 0;
    opacity: 0.7;
}

.youtube .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}

.youtube .play-button:before {
    content: '';
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: transparent transparent transparent #fff;
}

.youtube img,
.youtube .play-button {
    cursor: pointer;
}

.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
    position: absolute;
}

.youtube .play-button,
.youtube .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.game-description .header {
    display: block;
    position: relative;
}

.game-description .content {
    padding-top: 42px;
}

.statistics {
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
    padding-bottom: 34px;
    position: relative;
}

.statistics table {
    width: 100%;
    border-collapse: collapse;
}

.statistics table .table-heading th {
    text-align: center;
    padding: 19px 0px;
    padding-top: 24px;
    padding-bottom: 0px;
}

.statistics table thead th {
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: left;
    font-size: 20px;
    color: var(--body-title-color);
}

.statistics table tbody td div.game-col {
    display: flex;
    align-items: center;
}

.game-col .game-logo {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
}

.statistics table tbody td div.game-col img {
    width: 35px;
    margin-left: 4px;
}

.statistics table tbody td div.game-details {
    margin-left: 14px;
    font-size: 18px;
}

.statistics table tbody td div.game-details p {
    color: #7386a7;
}

.statistics table tbody td div.game-details a {
    color: #293447;
    text-decoration: none;
}

.statistics table tbody td:first-of-type {
    text-align: center;
    width: 100px;
}

.statistics table tbody td {
    height: 68px;
    text-align: left;
}

.statistics table tbody td:nth-of-type(1) {
    font-weight: 900;
}

.statistics table tbody tr:not(:last-of-type) td:nth-of-type(2),
.statistics table tbody tr:not(:last-of-type) td:nth-of-type(3),
.statistics table tbody tr:not(:last-of-type) td:nth-of-type(4),
.statistics table tbody tr:not(:last-of-type) td:nth-of-type(5) {
    border-bottom: 2px solid #e5eff9;
    width: 258px;
}

.statistics .bottom-notification {
    position: absolute;
    bottom: 6px;
    color: #a9bcdd;
    font-style: italic;
    left: 45px;
}

.faq-wrapper {
    margin-bottom: 72px;
}

.faq-heading {
    margin-bottom: 38px !important;
    padding-top: 68px !important;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #e5eff9;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-title h3 {
    color: var(--body-title-color);
    font-weight: normal;
    font-size: 28px;
    line-height: 98px;
    pointer-events: none;
}

.faq-btn {
    width: 49px;
    height: 49px;
    border: 0;
    background-color: transparent;
    margin-right: 42px;
}

.faq-btn:hover {
    cursor: pointer;
    background-color: #e9eef5;
    border-radius: 25px;
    transition: background-color 0.2s ease;
}

.faq {
    padding-left: 56px;
    transition: background-color 0.2s linear, font-weight 0.2s linear, border-radius 0.2s linear;
}

.faq.expanded + .faq {
    border-top: 0px;
}

.faq .faq-body {
    font-size: var(--font-size-content);
    line-height: 24px;
    color: #404447;
    padding-right: 142px;
    padding-bottom: 44px;
}

.faq.collapsed .faq-btn {
    background-image: url(../image/faq-expand.png);
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded {
    background-color: rgba(229, 239, 249, 0.8);
    border-radius: 15px;
}

.faq.expanded .faq-btn {
    background-image: url(../image/faq-collapse.png);
}

.faq.expanded .faq-title h3 {
    font-weight: 900;
}

.zfooter {
    position: relative;
    height: 541px;
}

.zfooter section {
    position: absolute;
    border-radius: 30px;
    float: left;
    height: 541px;
}

.zfooter section h2 {
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    margin-top: 49px;
}

.about-us-wrapper {
    width: 644px;
    background: #0d1c2d;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    z-index: 10;
}

.about-us-wrapper h2 {
    margin-left: 49px;
    margin-bottom: 46px;
}

.about-us-wrapper ul li {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.about-us-wrapper ul li img {
    padding: 26px 43px 0px 48px;
}

.about-us-wrapper ul li p {
    font-size: 18px;
    line-height: 24px;
    margin-right: 65px;
}

.project-timeline-wrapper {
    width: 720px;
    background: #dfebf9;
    z-index: 9;
    right: 0;
    padding-left: 234px;
    color: var(--body-text-color);
}

.project-timeline-wrapper ul {
    padding-left: 4px;
    font-size: 16px;
    margin-top: 40px;
    list-style-type: none;
}

.project-timeline-wrapper ul li {
    margin-bottom: 48px;
    text-indent: 24px;
    background-image: url(../image/bullet.png);
    background-repeat: no-repeat;
    background-position: left;
}

.project-timeline-wrapper ul li span {
    color: #2f89fc;
}

.project-timeline-wrapper ul li p {
    font-weight: 900;
    display: inline;
}

footer .copyright {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #828c98;
}

/* read more ↓↓↓ */

.m-readmore-btn .img,
.m-readmore-btn .txt,
.read-more .img,
.read-more .txt {
    pointer-events: none;
}

.desktop-slide-down {
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
    height: 0;
    overflow: hidden;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 768px) {
    .desktop-slide-down {
        transition: none;
    }

    /* Style for mobile devices */

    .mobile-detector {
        display: none;
    }

    .m-hidden {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .zcontainer {
        width: 100%;
    }

    header {
        background-image: url(../image/header.webp);
        background-repeat: no-repeat;
        background-position: 115px 270px;
        padding-left: 18px;
        padding-right: 18px;
    }

    header h1 {
        font-size: 34px;
        line-height: 41px;
        margin-bottom: 8px;
    }

    header p.line1 {
        font-size: 18px;
        line-height: 22px;
        font-weight: 900;
        margin-bottom: 22px;
    }

    header p.line2 {
        font-size: var(--font-size-content);
        line-height: 24px;
    }

    header .btn {
        width: 100%;
        margin-bottom: 0px;
    }

    nav ul {
        display: block;
        padding-bottom: 28px;
    }

    nav ul li {
        margin-top: 9px;
        margin-right: 0px;
        border-radius: 20px;
        background: linear-gradient(to right, transparent, #a2f4f5);
    }

    nav ul li a {
        width: 100%;
        height: 63px;
        line-height: 63px;
        font-size: var(--font-size-content);
        border-radius: 20px;
    }

    h2.title-1 {
        padding-top: 40px;
        margin-bottom: 31px;
        font-size: 26px;
        line-height: 36px;
    }

    .game-description,
    .statistics,
    .faq-wrapper,
    .about-us-wrapper,
    .project-timeline-wrapper {
        margin-left: 18px !important;
        margin-right: 18px !important;
    }

    .game-description .header {
        display: block;
        padding-top: 17px;
        margin-bottom: 8px;
    }

    .game-description .logo-wrapper {
        margin: 0px auto;
        width: 90%;
        top: -42px;
    }

    .game-description .game-title {
        font-size: 20px;
        margin-left: 21px;
        margin-top: 12px;
    }

    .game-description .content {
        margin-left: 21px;
        margin-right: 21px;
    }

    .game-description .content p {
        font-size: 15px;
    }

    .game-description .content p:last-of-type {
        margin-bottom: 0px;
    }

    .game-description .m-readmore-btn-wrapper,
    .game-description .read-more-wrapper {
        width: 100%;
    }

    .game-description .m-readmore-btn,
    .game-description .read-more {
        color: #f18539;
        height: 60px;
        width: 100%;
        border-radius: 20px;
        border: 1px solid #ff7b1e;
        background-color: transparent;
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
    }

    .game-description .m-readmore-btn:active,
    .game-description .read-more:active {
        background-color: #fff0e6;
    }

    .game-description .m-readmore-btn .img {
        background-image: url(../image/arrow.png);
        width: 16px;
        height: 18px;
        margin-left: 18px;
    }

    .game-description .footer .images {
        padding-left: 24px;
        position: relative;
        height: 215px;
    }

    .game-description .footer img {
        width: 274px;
        margin-left: 0px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .youtube img {
        top: -7.82%;
    }

    .game-description .video-wrapper iframe {
        min-height: auto;
    }

    /* Mobile read more slide area ↓↓↓ */

    .m-slide-down {
        -moz-transition: height 0.5s;
        -ms-transition: height 0.5s;
        -o-transition: height 0.5s;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
        height: 0;
        overflow: hidden;
    }

    /* Mobile slider ↓↓↓ */

    .m-slider-nav {
        width: 64px;
        margin: 8px auto 0px;
        padding-bottom: 20px;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .m-slider-nav ul li {
        list-style-type: none;
        width: 7px;
        height: 7px;
        background-color: #dedde2;
        border-radius: 12px;
    }

    .m-slider-nav ul li.active {
        background-color: #ff944a;
    }

    .m-slider-img {
        height: 0;
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    .m-slider-img.active {
        opacity: 1;
        height: auto;
    }

    /* Mobile statistics table ↓↓↓ */
    .statistics {
        overflow-y: scroll;
    }

    .statistics table {
        width: auto;
    }

    .statistics table tbody td:nth-of-type(1) {
        padding-right: 16px;
        padding-left: 18px;
    }

    .statistics table tbody td:not(:first-of-type) {
        padding: 0px 12px;
        width: auto;
    }

    .statistics table tbody td .game-col {
        width: 218px;
    }

    .faq-wrapper {
        margin-bottom: 38px;
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
        padding: 20px;
    }

    .faq {
        padding-left: 18px;
    }

    .faq-heading {
        padding-top: 18px !important;
        margin-bottom: 8px !important;
    }

    .faq-title h3 {
        font-size: 20px;
        line-height: 34px;
        max-width: 80%;
        padding-top: 26px;
        margin-bottom: 42px;
    }

    .faq .faq-btn {
        width: 35px;
        height: 35px;
        margin: 0;
        margin-right: 8px;
        background-size: cover;
    }

    .faq .faq-body {
        padding-right: 20px;
    }

    .about-us-wrapper,
    .project-timeline-wrapper {
        width: auto;
    }

    .about-us-wrapper h2 {
        margin-left: 24px;
        margin-bottom: 11px;
    }

    .about-us-wrapper ul li {
        display: block;
        margin-left: 28px;
        margin-bottom: 26px;
    }

    .about-us-wrapper ul li img {
        padding: 0;
        margin-bottom: 12px;
    }

    .project-timeline-wrapper {
        padding: 0;
        top: -70px;
        padding-top: 47px;
        width: auto;
        right: auto;
    }

    .project-timeline-wrapper ul {
        margin-top: 18px;
        padding-bottom: 16px;
    }

    .project-timeline-wrapper ul li {
        background-position: 26px 7px;
        margin-bottom: 32px;
        padding-right: 30px;
    }

    .project-timeline-wrapper ul li p {
        display: block;
        line-height: 20px;
        margin-left: 48px;
        margin-top: -18px;
    }

    .project-timeline-wrapper ul li span {
        padding-left: 24px;
    }

    .zfooter section {
        height: auto;
        position: relative;
        float: none;
    }

    .zfooter section h2 {
        font-size: 26px;
        margin-top: 42px;
        margin-left: 27px;
    }

    footer .copyright {
        position: initial;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: -76px;
    }

    footer .copyright span:nth-of-type(2) {
        margin-top: 12px;
    }
}

